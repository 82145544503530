<template>
  <v-container fluid>
      <v-tabs v-model="selectedTab">
        <v-tab :class="{ 'active-tab': selectedTab === 0 }">АУБ нарт</v-tab>
        <v-tab :class="{ 'active-tab': selectedTab === 1 }">Мэргэжлийн багш нарт</v-tab>
        <v-row justify="end" class="mx-4">
          <v-toolbar flat color="transparent">
            <v-row justify="space-between">
              <v-row justify="end">
                <v-btn
                  v-if="userData.role == 'superadmin'"
                  small
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 text-uppercase btn-danger bg-gradient-danger"
                  elevation="0"
                  :ripple="false"
                  @click="_newDialog(null)"
                  :title="'Youtube video нэмэx'"
                >
                  <v-icon class="darken-2" text>video+</v-icon>
                </v-btn>
              </v-row>
            </v-row>
          </v-toolbar>
        </v-row>
      </v-tabs>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col
                  lg="4"
                  md="6"
                  cols="12"
                  sm="12"
                  v-for="yvideo in filteredVideos"
                  :key="yvideo.id"
                >
                  <v-card class="card-shadow border-radius-xl">
                    <div class="px-4 py-4">
                      <p
                        class="text-sm mt-4 text-body"
                        v-if="isYoutube(yvideo.videoUrl)"
                      >
                        <iframe
                          width="100%"
                          height="240"
                          v-if="
                            yvideo.videoUrl != null &&
                            yvideo.videoUrl != '' &&
                            yvideo.videoUrl.toLowerCase().indexOf('youtube') !=
                              -1
                          "
                          v-bind:src="getYoutubeIframe(yvideo.videoUrl)"
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                      </p>

                      <div
                        v-else
                        style="
                          position: relative;
                          padding-bottom: 56.25%;
                          height: 0;
                        "
                      >
                        <iframe
                          :src="yvideo.videoUrl"
                          frameborder="0"
                          webkitallowfullscreen
                          mozallowfullscreen
                          allowfullscreen
                          style="
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                          "
                        ></iframe>
                      </div>
                      <hr class="horizontal dark mb-3" />
                      <v-row>
                        <v-col cols="2">
                          <v-avatar style="background-color: #f48fb1">
                            <h3>{{ yvideo.index }}</h3>
                          </v-avatar>
                        </v-col>
                        <v-col cols="8">
                          <!-- <h6 v-if="yvideo.selectedLessonCategory">
                  <v-icon> mdi-eye</v-icon>
                  {{ yvideo.selectedLessonCategory.name }}
                  {{ yvideo.participants }}
                </h6>
                <p v-else class="grey--text">
                  {{ yvideo.selectedRole.name }}
                </p> -->
                          <p class="text-typo font-weight-normal mt-2 mb-0">
                            {{ yvideo.name }}
                          </p>
                        </v-col>
                        <v-col
                          cols="1"
                          class="text-end"
                          v-if="userData.role == 'superadmin'"
                        >
                          <div class="d-flex">
                            <v-menu
                              transition="slide-y-transition"
                              offset-y
                              offset-x
                              min-width="150"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  :ripple="false"
                                  class="text-secondary ms-auto mt-3"
                                  v-bind="attrs"
                                  v-on="on"
                                  small
                                >
                                  <v-icon size="16">fas fa-ellipsis-v</v-icon>
                                </v-btn>
                              </template>

                              <v-list class="py-0">
                                <v-list-item
                                  class="list-item-hover-active"
                                  @click.stop="_editItem(yvideo, i)"
                                >
                                  <v-list-item-content class="pa-0">
                                    <v-list-item-title>
                                      Засаx
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                  class="list-item-hover-active"
                                  @click.stop="_deleteItem(yvideo)"
                                >
                                  <v-list-item-content class="pa-0">
                                    <v-list-item-title
                                      class="ls-0 text-body font-weight-600 mb-0"
                                    >
                                      Устгаx
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <hr class="horizontal dark" />
                              </v-list>
                            </v-menu>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col
                  lg="4"
                  md="6"
                  cols="12"
                  sm="12"
                  v-for="yvideo in filteredVideos2"
                  :key="yvideo.id"
                >
                  <v-card class="card-shadow border-radius-xl">
                    <div class="px-4 py-4">
                      <p
                        class="text-sm mt-4 text-body"
                        v-if="isYoutube(yvideo.videoUrl)"
                      >
                        <iframe
                          width="100%"
                          height="240"
                          v-if="
                            yvideo.videoUrl != null &&
                            yvideo.videoUrl != '' &&
                            yvideo.videoUrl.toLowerCase().indexOf('youtube') !=
                              -1
                          "
                          v-bind:src="getYoutubeIframe(yvideo.videoUrl)"
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                      </p>

                      <div
                        v-else
                        style="
                          position: relative;
                          padding-bottom: 56.25%;
                          height: 0;
                        "
                      >
                        <iframe
                          :src="yvideo.videoUrl"
                          frameborder="0"
                          webkitallowfullscreen
                          mozallowfullscreen
                          allowfullscreen
                          style="
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                          "
                        ></iframe>
                      </div>
                      <hr class="horizontal dark mb-3" />
                      <v-row>
                        <v-col cols="2">
                          <v-avatar style="background-color: #f48fb1">
                            <h3>{{ yvideo.index }}</h3>
                          </v-avatar>
                        </v-col>
                        <v-col cols="8">
                          <p class="text-typo font-weight-normal mt-2 mb-0">
                            {{ yvideo.name }}
                          </p>
                        </v-col>
                        <v-col
                          cols="1"
                          class="text-end"
                          v-if="userData.role == 'superadmin'"
                        >
                          <div class="d-flex">
                            <v-menu
                              transition="slide-y-transition"
                              offset-y
                              offset-x
                              min-width="150"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  :ripple="false"
                                  class="text-secondary ms-auto mt-3"
                                  v-bind="attrs"
                                  v-on="on"
                                  small
                                >
                                  <v-icon size="16">fas fa-ellipsis-v</v-icon>
                                </v-btn>
                              </template>

                              <v-list class="py-0">
                                <v-list-item
                                  class="list-item-hover-active"
                                  @click.stop="_editItem(yvideo, i)"
                                >
                                  <v-list-item-content class="pa-0">
                                    <v-list-item-title>
                                      Засаx
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                  class="list-item-hover-active"
                                  @click.stop="_deleteItem(yvideo)"
                                >
                                  <v-list-item-content class="pa-0">
                                    <v-list-item-title
                                      class="ls-0 text-body font-weight-600 mb-0"
                                    >
                                      Устгаx
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <hr class="horizontal dark" />
                              </v-list>
                            </v-menu>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    <v-dialog v-model="newDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline">
          Шинэ
          <span class="px-2 font-weight-bold text-danger">youtube</span> видео
          нэмэx
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model.trim="editedItem.name" label="Нэр" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.videoUrl"
                  label="Youtube link"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  return-object
                  :items="[
                    {
                      name: 'Teacher',
                      role: 'teacher',
                    },
                    {
                      name: 'Admin',
                      role: 'admin',
                    },
                    {
                      name: 'Director',
                      role: 'director',
                    },
                    {
                      name: 'Manager',
                      role: 'manager',
                    },
                    {
                      name: 'Student',
                      role: 'student',
                    },
                  ]"
                  item-text="name"
                  item-value="role"
                  v-model="editedItem.selectedRole"
                  label="Ангилалт сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <!-- <label class="text-xs text-typo font-weight-bolder ms-1"
                  >Тэмдэглэл</label
                > -->

                <v-textarea
                  v-model="editedItem.description"
                  outlined
                  label="Тэмдэглэл бичиx"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="saveItem"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      :max-width="400"
      :style="{ zIndex: 1 }"
      @keydown.esc="cancel"
    >
      <v-card class="py-4">
        <v-toolbar dense flat>
          <v-toolbar-title class="font-weight-bold">
            Устгаxыг xүсэж байна уу?
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text
          class="pa-4 black--text"
          v-html="editedItem.name"
        ></v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="deleteDialog = !deleteDialog"
            >Цуцлаx</v-btn
          >
          <v-btn color="red" dark class="font-weight-bold" @click="_deleteOK()"
            >Устгаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
export default {
  name: "RegularTablesView",
  data: () => ({
    selectedTab: 0,
    detailDialog: false,
    newDialog: false,
    newBulkDialog: false,
    deleteDialog: false,
    editedItem: {
      name: "",
      description: "",
    },
    editedIndex: -1,
    defaultItem: {
      name: "",
      description: "",
    },
    items: [],
    messageNotification: null,
    bulkText: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    filteredVideos(){
      if(this.items){
        return this.items.filter(video=> video.name.includes('[АУБ]'))
      }
      return []
    },
    filteredVideos2(){
      if(this.items){
        return this.items.filter(video=> !video.name.includes('[АУБ]'))
      }
      return []
    },

  },
  created() {
    this._read();
  },

  methods: {
    _add() {
      console.log();
    },
    _newDialog(item) {
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    _newBulkDialog() {
      this.newBulkDialog = true;
    },
    _deleteOK() {
      if (this.editedItem) {
        this.editedItem.ref.delete();
        this.editedItem = this.defaultItem;
      }
      this.deleteDialog = false;
    },
    _deleteItem(item) {
      console.log(item);
      this.editedItem = item;
      this.deleteDialog = true;
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
    },
    _closeEdit() {
      this.newDialog = false;
      this.newBulkDialog = false;
      this.messageNotification = null;
      this.bulkText = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveItem() {
      this.editedItem.createdAt = new Date();
      this.editedItem.public = true;

      if (this.editedItem.selectedLessonCategory != null) {
        this.editedItem.lessonCategoryRef =
          this.editedItem.selectedLessonCategory.ref;
      }
      if (this.editedItem.name && this.editedItem.name.trim() !== "") {
        if (this.editedIndex === -1) {
          // Object.assign(this.participants[this.editedIndex], this.editedItem);
          fb.dbDSStorage
            .collection(this._getPath())
            .doc()
            .set(this.editedItem)
            .then(() => {})
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        } else {
          this.editedItem.ref.update(this.editedItem);
        }
        this._closeEdit();
      } else {
        this.messageNotification =
          "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    _read() {
      var query = fb.dbDSStorage.collection(this._getPath());
      if (this.userData.role == "teacher") {
        // query = query.where("selectedRole.role", "==", this.userData.role);
        query
          .where("public", "==", true)
          .orderBy("index", "desc")
          .onSnapshot((querySnapshot) => {
            this.items = [];
            querySnapshot.forEach((doc) => {
              let item = doc.data();
              item.id = doc.id;
              item.ref = doc.ref;
              if(!item.createdAt) item.ref.update({createdAt: new Date()})
              this.items.push(item);
            });
          });
      } else if (this.userData.role == "student") {
        query = query.where("selectedRole.role", "==", this.userData.role);
        query.where("public", "==", true).onSnapshot((querySnapshot) => {
          this.items = [];
          querySnapshot.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            if(!item.createdAt) item.ref.update({createdAt: new Date()})
            this.items.push(item);
          });
        });
      } else if (this.userData.role == "schoolmanager") {
        query = query.where("selectedRole.role", "==", this.userData.role);
        query.where("public", "==", true).onSnapshot((querySnapshot) => {
          this.items = [];
          querySnapshot.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            if(!item.createdAt) item.ref.update({createdAt: new Date()})
            this.items.push(item);
          });
        });
      } else {
        query.where("public", "==", true).orderBy("createdAt","asc").onSnapshot((querySnapshot) => {
          this.items = [];
          querySnapshot.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            if(!item.createdAt) item.ref.update({createdAt: new Date()})
            this.items.push(item);
          });
        });
      }
    },
    copy() {
      fb.dbDSStorage
        .collection("categories")
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            fb.db.collection("categories").add(doc.data());
          });
        });
    },
    _getPath() {
      return "tutorialVideos";
    },
    getYoutubeId(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
    getYoutubeIframe(url) {
      if (
        url != null &&
        url != "" &&
        url.toLowerCase().indexOf("youtube") != -1
      ) {
        var id = this.getYoutubeId(url);
        return "https://www.youtube.com/embed/" + id;
      } else {
        return "https://www.loom.com/embed/459052232f0c4df69be88d71515571d4";
      }
    },
    isYoutube(url) {
      if (
        url != null &&
        url != "" &&
        url.toLowerCase().indexOf("youtube") != -1
      )
        return true;
      else return false;
    },
  },
};
</script>
<style>
.active-tab {
  background-color: #FFC107; /* Your desired background color */
  color: #ffffff; /* Your desired text color */
}
</style>