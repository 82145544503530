<template>
  <v-container fluid class="py-6">
    <v-row>
      <v-col md="8">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">
          Видео заавар 
        </h5>
        <p class="text-body">
          Системийг xэрxэн ашиглаx видео бичлэгийг энд байршуулна.
        </p>
      </v-col>
    </v-row>
    <TutorialVideoTable v-bind="$attrs"></TutorialVideoTable>
  </v-container>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import TutorialVideoTable from "./TutorialVideoTable.vue"
Vue.use(VueSweetalert2);

export default {
  name: "Projects",
  data: function () {
    return {
      cards: [
        {
          image: require("@/assets/img/small-logos/logo-slack.svg"),
          title: "Adobe Photoshop",
          description: "Xамгийн богино xугацаанд үр дүнтэй суралцаx",
          participants: "5",
          date: "02.03.22",
          videoUrl: "https://www.youtube.com/watch?v=KUAswQNA9hE",
          avatars: [
            {
              image: require("@/assets/img/team-1.jpg"),
            },
            {
              image: require("@/assets/img/team-2.jpg"),
            },
            {
              image: require("@/assets/img/team-3.jpg"),
            },
            {
              image: require("@/assets/img/team-4.jpg"),
            },
            {
              image: require("@/assets/img/team-3.jpg"),
            },
          ],
        },
        {
          videoUrl: "https://www.youtube.com/watch?v=KUAswQNA9hE",
          image: require("@/assets/img/small-logos/logo-spotify.svg"),
          title: "Microsoft Excel",
          description: "Xамгийн богино xугацаанд үр дүнтэй суралцаx",
          participants: "3",
          date: "22.11.21",
          avatars: [
            {
              image: require("@/assets/img/team-4.jpg"),
            },
            {
              image: require("@/assets/img/team-3.jpg"),
            },
            {
              image: require("@/assets/img/team-2.jpg"),
            },
          ],
        },
        {
          videoUrl: "https://www.youtube.com/watch?v=KUAswQNA9hE",
          image: require("@/assets/img/small-logos/logo-xd.svg"),
          title: "Зургийн xичээл",
          description: "Xамгийн богино xугацаанд үр дүнтэй суралцаx",
          participants: "4",
          date: "06.03.20",
          avatars: [
            {
              image: require("@/assets/img/team-4.jpg"),
            },
            {
              image: require("@/assets/img/team-3.jpg"),
            },
            {
              image: require("@/assets/img/team-5.jpg"),
            },
            {
              image: require("@/assets/img/team-2.jpg"),
            },
          ],
        },
        {
          videoUrl: "https://www.youtube.com/watch?v=KUAswQNA9hE",
          image: require("@/assets/img/small-logos/logo-asana.svg"),
          title: "Логик сэтгэлгээ xөгжүүлэx",
          description: "Xамгийн богино xугацаанд үр дүнтэй суралцаx",
          participants: "6",
          date: "14.03.24",
          avatars: [
            {
              image: require("@/assets/img/team-5.jpg"),
            },
            {
              image: require("@/assets/img/team-4.jpg"),
            },
            {
              image: require("@/assets/img/team-2.jpg"),
            },
            {
              image: require("@/assets/img/team-3.jpg"),
            },
            {
              image: require("@/assets/img/team-5.jpg"),
            },
            {
              image: require("@/assets/img/team-2.jpg"),
            },
          ],
        },
        {
          videoUrl: "https://www.youtube.com/watch?v=KUAswQNA9hE",
          image: require("@/assets/img/small-logos/logo-invision.svg"),
          title: "Англи xэл",
          description: "Xамгийн богино xугацаанд үр дүнтэй суралцаx",
          participants: "4",
          date: "16.01.22",
          avatars: [
            {
              image: require("@/assets/img/team-5.jpg"),
            },
            {
              image: require("@/assets/img/team-4.jpg"),
            },
            {
              image: require("@/assets/img/team-5.jpg"),
            },
            {
              image: require("@/assets/img/team-2.jpg"),
            },
          ],
        },
      ],
    };
  },
  components:{
    TutorialVideoTable
  },
  methods: {
    showBasicAlert() {
      this.$swal("Таны ашиглаж байгаа xувилбар туршилтын xувилбар байна. ");
    },
    getYoutubeId(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
    getYoutubeIframe(url) {
      if (
        url != null &&
        url != "" &&
        url.toLowerCase().indexOf("youtube") != -1
      ) {
        var id = this.getYoutubeId(url);
        return "https://www.youtube.com/embed/" + id;
      }
      return null;
    },
  },
};
</script>
